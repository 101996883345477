import React, { Component } from 'react';
import { lazyload } from 'react-lazyload';

@lazyload({
  height: 500,
  once: true,
  offset: 100
})
class AnyEvent extends Component {
  render() {
    return (
      <section className="NewSection u-textCenter">
        <h3>Perfect for any type of event</h3>
        <div className="u-grid-row u-mt2">
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/bubbles.svg" />
            <p>Corporate catering</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/weddingrings.svg" />
            <p>Wedding catering</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/rockon.svg" />
            <p>Music festivals</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/ribboncutting.svg" />
            <p>Store openings</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/cap.svg" />
            <p>School events</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/donationbox.svg" />
            <p>Fundraisers</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/movieset.svg" />
            <p>Production catering</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/party.svg" />
            <p>Community events</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/stage.svg" />
            <p>Large scale events</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/mask.svg" />
            <p>Private events</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/cake.svg" />
            <p>Birthday parties</p>
          </div>
          <div className="u-grid-col u-grid-md-quarter u-grid-sm-third u-mb3 u-grid-half">
            <img src="https://static.seattlefoodtruck.com/badges/winner.svg" />
            <p>Employee appreciation</p>
          </div>
        </div>
      </section>
    );
  }
}

export default AnyEvent;
